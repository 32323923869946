

<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="12"><div class="text-wrapper">{{ output }}</div></el-col>
    </el-row>
    <el-row v-if="output" type="flex" justify="center">
      <el-col :span="12"><el-button type="primary" size="small" v-clipboard:copy="output" v-clipboard:success="onCopy">复制</el-button></el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row type="flex" justify="center" :gutter="20">
      <el-col :span="12"><el-input class="input" placeholder="请输入邀请码(用户ID)" v-model="inviter_id"></el-input></el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="12"><el-button type="primary" @click="onCreateUser">邀请/创建用户</el-button></el-col>
      <!-- <el-col :span="12"><el-button type="primary" @click="onQueryUser">查询用户</el-button></el-col> -->
    </el-row>
    <el-divider></el-divider>
    <el-row type="flex" justify="center" :gutter="20">
      <el-col :span="12"><el-input class="input" placeholder="请输入用户ID" v-model="user_id"></el-input></el-col>
      <el-col :span="12"><el-input class="input" placeholder="充值数量" v-model="charge_amount"></el-input></el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="12"><el-button type="primary" @click="onRecharge">充值</el-button></el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'Gpt',
  components: {
  },
  data() {
    return {
      inviter_id: '',
      output: '',
      charge_amount: '',
      user_id: '',
    }
  },
  mounted() {
    // Vue.use(VueClipboard);
  },
	methods: {
		onCreateUser() {
      this.CreateUser()
    },

    onQueryUser() {

    },

    onCopy() {
      this.$message.success('复制成功');
    },

    onRecharge() {
      let amount = Number(this.charge_amount)
      let user_id = Number(this.user_id)
      if (!amount || !user_id) {
        this.$message.error('参数有误');
        return;
      }
      this.Recharge(user_id, amount);
    },
    
    // CreateUser
    CreateUser() {
      let inviter = this.inviter_id ? parseInt(this.inviter_id) : 0;
      this.$http
        .post('/cspr/api/gpt-manage', {
          action: 'CreateUser',
          inviter_id: inviter,
        })
        .then(response => {
          let rsp = response.data
          console.log(rsp)
          if (rsp.code != 0) {
            this.$message.error(rsp.msg);
          } else {
            let site = '登录地址: https://gipiti.fun/password?pass=' + rsp.data.token + '\n';
            let output = site + '邀请码: ' + rsp.data.user_id + '\n' + '剩余额度: ' + rsp.data.count + '条'
            // let output = site + '登录密码: ' + rsp.data.token + '\n' + '邀请码: ' + rsp.data.user_id + '\n' + '剩余额度: ' + rsp.data.count + '条'
            this.output = output;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },    
    
    // Recharge
    Recharge(user_id, count) {
      this.$http
        .post('/cspr/api/gpt-manage', {
          action: 'UserRecharge',
          user_id: user_id,
          count: count,
        })
        .then(response => {
          let rsp = response.data
          console.log(rsp)
          if (rsp.code != 0) {
            this.$message.error(rsp.message);
          } else {
            this.$message.success('充值成功');
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },   
  }
}
</script>

<style>
.text-wrapper {
  white-space: pre-wrap;
}

.row {
  border-top: 1px black;
  margin-bottom: 20px;
  
}
/* .login {
  height: 200px;
  display:flex;
  justify-content:center; 
  align-items:center;  
} */

/* .input {
  margin: 30px 0;
} */
</style>