<template>
  <div class="login">
     <div>
      <img alt="Vue logo" src="../assets/rye.png">
     <div>
        <el-input class="input" placeholder="请输入密码" v-model="input" show-password></el-input>
        <el-button type="primary" @click="onLogin">登录</el-button>
      </div>

     </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      input: ''
    }
  },
	methods: {
		onLogin () {
      if (this.input != '') {
        this.httpPostLogin()
      }
    },
    
    // 登录
    httpPostLogin() {
      this.$http
        .post('/api/catcher_login', {
          password: this.input,
        })
        .then(response => {
          if (response.data.code != 0) {
            this.$message.error('密码错了哦');
          } else {
            // 登录成功，重新加载页面
            localStorage.catcherToken = response.data.data.token
            console.log('token:', this.STATE.catcherToken)
            this.$router.push('/')
            location.reload()
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
      
  }
}
</script>

<style>  
.login {
  height: 800px;
  display:flex;
  justify-content:center; 
  align-items:center;  
}

.input {
  margin: 30px 0;
}
</style>