import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import router from './router/index.js'
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'

// ===== Vue 配置 =====
Vue.config.productionTip = false

// ===== 全局变量 =====
Vue.prototype.STATE = {
  isLogin: false,
  catcherToken: '',
}

// ===== axios 配置 =====
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://dev.catcher.cash:8080/' : 'https://catcher.cash/'
axios.defaults.withCredentials = true
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // console.log(config)
  var headers = config['headers']
  headers['X-CATCHER-TOKEN'] = Vue.prototype.STATE.catcherToken
  return config
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });
  
Vue.prototype.$http = axios

// ===== echarts 配置 =====
var echarts = require('echarts')
Vue.prototype.$echarts = echarts


Vue.use(ElementUI);
Vue.use(VueClipboard);

console.log(process.env.NODE_ENV)


// 登录状态检查
// router.beforeEach((to, from, next) => {
  // 跳转其他页面 并且是未登录状态， 强制跳转至登录页
  // if (to.path !== '/login' && !Vue.prototype.STATE.isLogin) {
  //   console.log('未登录')
  //   next({
  //     path: '/login',
  //   })
  // } else {
  //   next()
  // }
// })

new Vue({
  router,
  data: {

  },
  render: h => h(App),
}).$mount('#app')
