<template>
  <div class="home">
     <div>
      <img alt="Vue logo" src="../assets/rye.png">
      <HelloWorld msg="Welcome"/>
<!--      <div>
        <el-input placeholder="请输入密码" v-model="input" show-password @keydown.enter="addTodo"></el-input>
        <el-button type="primary" @click="onLogin">登录</el-button>
      </div> -->

     </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data() {
    return {
      input: ''
    }
  },
	methods: {
		onLogin () {
      alert(this.input)
    }
  }
}
</script>

<style>
.home {
  height: 600px;
  display:flex;
  justify-content:center; 
  align-items:center;  
}
</style>