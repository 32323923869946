<template>
	<div class="navigator">
		<el-menu :default-active="activeIndex" mode="horizontal" :router="true" @select="handleSelect">
			<el-menu-item><img width="40px" height="40px" alt="Vue logo" src="../assets/rye.png"></el-menu-item>
			<el-menu-item index="/">首页</el-menu-item>
			<el-menu-item index="/gpt">GPT 管理</el-menu-item>
			<el-submenu index="/indicator">
				<template slot="title">指标列表</template>
				<el-menu-item index="/indicator/Volume">Volume 成交量</el-menu-item>
				<el-menu-item index="/indicator/AllMarket">AllMarket 全市场</el-menu-item>
			</el-submenu>
			<el-menu-item index="/job">Job 管理</el-menu-item>
			<el-submenu index="/futures">
				<template slot="title">合约</template>
				<el-menu-item index="/futures/traders">策略交易记录</el-menu-item>
				<el-menu-item disabled index="/futures">待定</el-menu-item>
			</el-submenu>
			<el-menu-item index="4" disabled>资金费率</el-menu-item>
			<el-menu-item index="/milestone">里程碑</el-menu-item>
			<el-menu-item index="/login">登出</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
	export default {
		name: 'Navigator',
		data() {
			return {
				activeIndex: '/',
			};
		},
		methods: {
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
				if (key == '/login') {
					localStorage.removeItem('catcherToken')
					location.reload()
				}
				// this.$router.push(keyPath)
			}
		},
	}
</script>

<style>
	.navigator {
		margin-bottom: 30px;
	}
</style>
