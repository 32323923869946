<template>
  <div id="app">
    <!-- 导航栏 -->
    <Navigator v-if="isLogin"/>
    <!-- 页面内容 -->
    <router-view id="router-view"></router-view>
  </div>
</template>

<script>

import Navigator from './components/Navigator.vue'

export default {
  name: 'App',
  components: {
    Navigator,
  },
  data() {
    return {
      isLogin: false
    }
  },
  methods: {
    // 请求指标分析数据
    // httpGetIsLogin() {
    //   this.$http
    //     .get('http://dev.catcher.cash:8080/is_login')
    //     .then(response => {
    //       if (response.status != 200 || response.data.code != 0) {
    //         if (this.$route.path != '/login') {
    //           this.$router.push('/login')
    //         }
    //       } else {
    //         this.STATE.isLogin = true
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     });
    // },
    
    // 判断是否有登录态
    checkLoginStatus() {
      if (!localStorage.catcherToken) {
        console.log('没有登录态')
        if (this.$route.path != '/login') {
          this.$router.push('/login')
        }
      } else {
        console.log('有登录态')
        this.isLogin = true
        this.STATE.catcherToken = localStorage.catcherToken
        if (this.$route.path == '/login') {
          this.$router.push('/')
        }
      }
    }
  },
  
  
  mounted() {
    this.checkLoginStatus()
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a {
  text-decoration: none;
  color: #909399;
}

</style>
